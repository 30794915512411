// @ts-nocheck

import React from "react";
import RoleMenu from "./RoleMenu";
import { useAuth } from "../../context/auth/auth-context";
import Header from "../../components/Header";
import { useLoading } from "../../context/loading-context";

export default function Menu() {
  const { user, logout } = useAuth();

  console.log({ user });

  const { loader, setLoader } = useLoading();

  React.useEffect(() => {
    setLoader(false);
  }, []);

  return (
    <>
      <Header text={"Menu"}>
        <div id="twMainContentView">
          <div
            data-role="collapsible-set"
            data-inset="false"
            data-mini="true"
            data-theme="a"
            data-content-theme="a"
            data-collapsed-icon="carat-r"
            data-expanded-icon="carat-d"
            data-iconpos="right"
            className="ui-collapsible-set ui-group-theme-a"
          >
            <RoleMenu role={user.role} />
          </div>
          <ul
            data-role="listview"
            data-inset="false"
            style={{ marginTop: "16px" }}
            className="ui-listview"
          >

            <li
              style={{ padding: "0" }}
              className="ui-li-static ui-body-inherit ui-last-child"
            >
              <form
                method="post"
                id="logoutForm"
                className="nested-form"
                action="#"
              >
                {" "}
                <input type="hidden" name="routeAction" value="sign-out" />
                <div
                  onClick={logout}
                  className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-mini"
                  style={{textAlign:'center',margin:'10px 40px',padding:'10px', background:'rgb(71, 145, 219)', color:'white', borderRadius:'10px'}}
                >
                  Logout
                  <input
                    type="button"
                    data-mini="true"
                    value="Logout"
                    data-icon="user"
                    data-iconpos="right"
                  />
                </div>
              </form>
            </li>
          </ul>
        </div>
      </Header>
    </>
  );
}
