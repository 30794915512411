import React from "react";

class ChatwootWidget extends React.Component {
  componentDidMount() {
    let user = this.props.user;

    // Add Chatwoot Settings
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: "right", // This can be left or right
      locale: "ar", // Language to be set
      type: "expanded_bubble", // [standard, expanded_bubble]
    };

    (function (d, t) {
      var BASE_URL = "https://app.chatwoot.com";
      var g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: "KVHT8Y86RteFeKmuhdvHXAmb",
          baseUrl: BASE_URL,
        });
      };
    })(document, "script");

    window.addEventListener("chatwoot:ready", function () {
      console.log("ok");
      if (user) {
        window.$chatwoot.setUser(`#${user.username}_${user.id}`, {
          email: `${user.username}@iris.bet`,
          name: `${user.username}`,
          description: `Role: ${user.role}`,
        });
      }
    });
  }
  render() {
    return null;
  }
}

export default ChatwootWidget;
