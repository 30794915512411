// @ts-nocheck

import { parseDomain, fromUrl } from "parse-domain";

import orgs from "./orgs.json";

export const isEmpty = (value: string | number | object): boolean => {
	if (value === null) {
		return true;
	} else if (typeof value !== "number" && value === "") {
		return true;
	} else if (typeof value === "undefined" || value === undefined) {
		return true;
	} else if (
		value !== null &&
		typeof value === "object" &&
		!Object.keys(value).length
	) {
		return true;
	} else {
		return false;
	}
};

export function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const amountFormat = (number) => {
	return parseFloat(number).toFixed(2);
};

export function getReferenceId(uuid) {
	//return uuid.toUpperCase();
	try {
		let splited_uuid = uuid.split("-");
		return splited_uuid[splited_uuid.length - 1].toUpperCase();
	} catch (err) {
		return uuid;
	}
}

function extractMainDomain(domain) {
	return domain.replace("mycard.", "");
	// Improved regular expression
	/*const match = domain.match(
		/(?:[a-z0-9-]{1,63}\.)?([a-z0-9-]{1,63}\.[a-z.]{2,6})$/
	);

	// If a match is found, return the matched domain
	// Otherwise, return the original domain
	return match ? match[1] : domain;*/
}

export function getOrgConfig() {
	let self_location = window.location.href;

	let self_hostname = new URL(self_location).hostname;

	let self_main_domain = extractMainDomain(self_hostname);

	let selected_organization = "8b8fb666-a770-49b9-89d7-f9d805b620d6";

	//console.log({ org_domain, selected_organization, orgs });

	return !isEmpty(selected_organization)
		? { ...selected_organization, org_theme: self_main_domain }
		: {};
}
