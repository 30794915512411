// @ts-nocheck
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthProvider } from "./auth/auth-context";
import { LoadingProvider } from "./loading-context";
import { getOrgConfig } from "../utils/orgs-utils";

//import styles
import "../jqm-styles.css";


const Apollo365Theme = React.lazy(() => import("../themes/apollo365/theme"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function AppProviders({ children }) {
  let organization = getOrgConfig();

  let { org_theme } = organization;

  const themeComponent = <Apollo365Theme />;

  return (
    <>
      <React.Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <LoadingProvider>
              <AuthProvider organization={organization}>
                {themeComponent}
                {children}
              </AuthProvider>
            </LoadingProvider>
          </Router>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </React.Suspense>
    </>
  );
}

export { AppProviders };
