// @ts-nocheck

import React from "react";
import Admin from "./Admin";
import Agency from "./Agency";
import Player from "./Player";
import Shop from "./Shop";
import Transactions from "./Transactions";
import Vouchers from "./Vouchers";
import Cercuit from "./Cercuit";

export default function RoleMenu({ role }) {
	switch (role) {
		case "super":
			return (
				<>
					<Admin />
					<Transactions />
					
		
				</>
			);

		case "admin":
			return (
				<>
					<Agency />
					<Shop />
					<Player />
					<Transactions />
					
				</>
			);

		case "agent":
			return (
				<>
					<Shop />
					<Player />
					<Transactions />
				
				</>
			);
		case "shop":
			return (
				<>
					<Player />
					<Transactions />
				</>
			);
		default:
			return <>404</>;
	}
}
